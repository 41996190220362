import React from "react"
import AboutUsSection from "../components/AboutUsSection"
// import BabyMenuSection from "../components/BabyMenuSection"
import DeliverySection from "../components/DeliverySection"
import ContactSection from "../components/DeliverySection/ContactSection"
import Header from "../components/Header"
import Hero from "../components/Hero"
import Layout from "../components/layout"
import NewsSection from "../components/NewsSection"
import MenuSection from "../components/NormalMenuSection"
import { MenuProvider } from "../components/NormalMenuSection/MenuContext"
import PricingSection from "../components/PricingSection"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Пълноценна храна за вашето дете" />
    <Header />
    <Hero />
    <NewsSection />
    <MenuProvider>
      <MenuSection />
      {/* <BabyMenuSection /> */}
    </MenuProvider>
    <DeliverySection />
    <PricingSection />
    <AboutUsSection />
    <ContactSection />
    <div
      style={{
        color: "#ccc",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
    <p>Снимка: iStock</p>
    </div>
  </Layout>
)

export default IndexPage


/**
 * <a
        style={{ color: "#ccc" }}
        href="https://www.freepik.com/free-vector/flat-summer-background_13840247.htm#fromView=search&page=1&position=12&uuid=2a37da46-dd3b-4a5c-aa76-dfd2fc283faa"
      >
        Hero image by Freepik
      </a> */ 